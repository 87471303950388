<template>
  <div
    class="v-application v-application--is-ltr theme--light menuable__content__active"
  >
    <!--begin::Attendance-->
    <div class="row">
      <!-- <div class="col-xxl-3 col-12 col-md-3 pt-0 pb-0">
        <div class="card gutter-b">
          <b-form-select
            v-model="selected"
            :options="filterArea"
          ></b-form-select>
        </div>
      </div> -->
      <!-- <div 
        class="col-xxl-3 col-12 col-md-3 pt-0 pb-0" 
        v-if="permission.some((item) => item.name === 'can_view_dashboard')">
        <div class="card gutter-b">
          <b-form-select
            v-model="selected.customer"
            :options="filterCustomer"
          ></b-form-select>
        </div>
      </div> -->
      <div class="col-xxl-3 col-12 col-md-3 pt-0 pb-0" v-show="false">
        <b-form-group class="mb-0" v-slot="{ ariaDescribedby }">
          <b-input-group>
            <b-form-select
              id="sort-by-select"
              v-model="refSortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="w-75"
            >
              <template #first>
                <option value="">-- Sort by --</option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="refSortDirectionChange"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="col-xxl-2 col-12 col-md-3 pt-0 pb-0">
        <div class="card gutter-b">
          <b-button variant="primary" class="w-100">Export Excel</b-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-12 col-12 col-md-12">
        <b-card>
          <b-button
            variant="primary gutter-b"
            @click="handleButtonAdd()"
            v-if="
              permission.some((item) => item.name === 'can_create_customer')
            "
            >Tambah</b-button
          >
          <div class="row justify-content-between">
            <div class="col-md-1">
              <b-form-group class="mb-0">
                <b-form-select
                  id="per-page-select"
                  v-model="refPerpage"
                  :options="pageOptions"
                  size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group class="mb-0">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text>
                      <i class="menu-icon flaticon-search"></i>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="params.query"
                    placeholder="Search"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="table-responsive">
            <b-table
              hover
              :items="listTable"
              :fields="columns"
              :per-page="refPerpage"
              :current-page="refCurrentPage"
              :busy="isLoading"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-empty
            >
              <template #cell(id)="row">
                <span class="mr-2">
                  <span
                    :title="row.item.id"
                    class="cursor-pointer"
                    v-clipboard:copy="row.item.id"
                    v-clipboard:success="onCopy"
                    >{{ sortChar(row.item.id) }}</span
                  >
                  <span> </span>
                </span>
              </template>
              <template #cell(action)="row">
                <span
                  class="mr-2"
                  @click="handleEdit(row.item)"
                  v-if="
                    permission.some((item) => item.name === 'can_edit_customer')
                  "
                >
                  <b-button
                    variant="primary"
                    class="py-1 px-2"
                    id="edit"
                    title="edit"
                  >
                    <i class="menu-icon flaticon-edit pr-0"></i>
                  </b-button>
                </span>
                <span class="mr-2" @click="handleDelete(row.item)">
                  <b-button
                    variant="danger"
                    class="py-1 px-2"
                    id="detail"
                    title="detail"
                  >
                    <i class="menu-icon flaticon-delete pr-0"></i>
                  </b-button>
                </span>
                <span @click="handleBranch(row.item)">
                  <b-button
                    variant="success"
                    class="py-1 px-2"
                    id="branch"
                    title="view site"
                  >
                    <i class="menu-icon flaticon-network pr-0"></i>
                  </b-button>
                </span>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </div>
          <div class="row justify-content-end">
            <div class="col-md-3">
              <b-pagination
                v-model="refCurrentPage"
                :total-rows="totalData"
                :per-page="refPerpage"
                size="md"
                align="fill"
                class="ml-auto"
                style="padding: 0"
              ></b-pagination>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <!--end::Attendance-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { sortChar } from "@/utils/helper";
import { debounce } from "debounce";
import { mapGetters } from "vuex";
import mixin from "@/mixins/index";

export default {
  name: "ListEmployee",
  mixins: [mixin],
  data() {
    return {
      filterArea: [{ value: null, text: "Filter Area Kerja" }],
      filterCustomer: [{ value: null, text: "Filter Customer" }],
      selected: {
        customer: null,
      },
      isLoading: false,
      rowStyleOption: {
        stripe: true,
      },
      search: "",
      pageIndex: 1,
      totalRows: 10,
      currentPage: parseInt(this.$route.query.offset) || 1,
      pageSize: 10,
      perPage: parseInt(this.$route.query.limit) || 10,
      pageOptions: [10, 15, { value: 100, text: "Show a lot" }],
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      columns: [
        {
          key: "id",
          label: "ID",
          align: "left",
          sortBy: "id",
        },
        // {
        //   key: "parent",
        //   label: "Parent",
        //   align: "left",
        //   sortBy: "",
        // },
        {
          key: "name",
          label: "Nama",
          align: "left",
          sortBy: "name",
          width: "30%",
          sortable: true,
        },
        // {
        //   key: "customer_branches",
        //   label: "Cabang",
        //   align: "left",
        //   sortBy: "",
        //   sortable: true,
        // },
        {
          key: "contract_start",
          label: "Tanggal Berlaku Perjanjian",
          align: "left",
          width: "",
          sortBy: "contract_start",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          align: "center",
        },
      ],
      sortBy: "",
      sortDesc: false,
      listTable: [],
      totalData: 10,
      filter: null,
      filterOn: [],
      params: {
        query: this.$route.query.query || "",
        sort: this.$route.query.sort || "created_at",
        order: this.$route.query.order || "desc",
        limit: parseInt(this.$route.query.limit) || 10,
        offset:
          (parseInt(this.$route.query.offset) - 1) *
            parseInt(this.$route.query.limit) || 0,
      },
      timer: 0,
    };
  },
  components: {},
  computed: {
    ...mapGetters(["permission"]),
    refCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        // this.currentPage = value;
        this.params.offset = (value - 1) * this.params.limit;
      },
    },
    refPerpage: {
      get() {
        return this.perPage;
      },
      set(value) {
        this.perPage = value;
        this.params.limit = value;
      },
    },
    refSortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.sortBy = value;
        this.params.sort = value;
      },
    },
    refSortDirectionChange: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.sortDesc = value;
        if (value) this.params.order = "desc";
        else this.params.order = "asc";
      },
    },

    sortOptions() {
      return this.columns
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    this.getTabel();
    this.filterCustomer = await this.getCustomer();
    this.selected.customer = this.filterCustomer[1].value;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Site Group" },
    ]);
  },
  methods: {
    sortChange(params) {
      Object.entries(params).forEach((item) => {
        if (item[1]) {
          // eslint-disable-next-line
          console.log(item[0], item[1]);
        }
      });
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    // page size change
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    },
    handleButtonAdd() {
      this.$router.push({ path: `/customer/create` });
    },
    handleEdit(item) {
      this.$router.push({ path: `/customer/edit/${item.id}` });
    },
    async getTabel() {
      this.isLoading = true;
      try {
        await this.$axios
          .get(`${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/customers`, {
            params: this.params,
          })
          .then(({ data }) => {
            this.listTable = data.list;
            this.totalData = data.total;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
    processChange: debounce(function () {
      this.getTabel();
    }, 500),
    onCopy(e) {
      this.$bvToast.toast(e.text, {
        title: "Successfuly Copy to clipboard",
        variant: "success",
        solid: true,
      });
    },
    handleDelete(item) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to deactive this user.`, {
          title: "Confirm Delete",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (trigger) => {
          if (trigger) {
            try {
              await this.$axios
                .delete(`/api/v1/cms/customer/${item.id}`)
                .then(() => {
                  this.processChange();
                });
              this.$bvToast.toast("Success Deleted Site Group", {
                title: "Success",
                variant: "success",
                solid: true,
              });
              this.setDefaultParams();
            } catch ({ response }) {
              this.$bvToast.toast(response.data.message, {
                title: "Error",
                variant: "danger",
                solid: true,
              });
            }
          }
        });
    },
    handleBranch(item) {
      this.$router.push({ path: `/customer/branch/list/${item.id}` });
    },
    performFilter: debounce(function () {
      const query = {};
      if (this.params.query) {
        query.query = this.params.query;
      }
      if (this.params.sort) {
        query.sort = this.params.sort;
      }
      if (this.params.order) {
        query.order = this.params.order;
      }
      query.limit = this.params.limit;
      query.offset = this.currentPage;
      this.$router.push({ path: "/customer/list", query });
      this.processChange();
    }, 500),
    setDefaultParams() {
      this.currentPage = 1;
      this.params = {
        query: this.$route.query.query || "",
        sort: "created_at",
        order: "desc",
        limit: 10,
        offset: 0,
      };
    },
    sortChar,
  },
  watch: {
    params: {
      handler(oldValue, newValue) {
        if (oldValue.query !== newValue.query) {
          this.query.offset = 1;
        }
        if (oldValue.limit !== newValue.limit) {
          this.query.offset = 1;
        }
        this.processChange();
        // this.performFilter();
      },
      deep: true,
    },
  },
};
</script>
